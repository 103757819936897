


















import { defineComponent } from '@nuxtjs/composition-api';

import { APP_ROUTER_PATHS } from '~/constants';

export default defineComponent({
  name: 'ProductTabSecurity',

  data() {
    return {
      link: APP_ROUTER_PATHS.hpSecurity,
    };
  },
});
